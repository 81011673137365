import React from 'react';
import { useParams } from "react-router-dom";
import cn from "classnames";

function Dictionary() {
    const { term } = useParams();

    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6 my-5">

                    {term == 'blockchain' && <BlockchainDef />}
                    {term == 'protocol' && <ProtocolDef />}
                    {term == 'liquidity' && <LiquidityDef />}
                    
                </div>
            </div>
        </div>
    );
}

function BlockchainDef({}) {
    return (
        <>
            <h2>Blockchain</h2>

            <p>What is a blockchain?</p>

            <h5>Short answer</h5>
            <p>When transactions are processed on a cryptocurrency network, they're first sorted into groups - or 'blocks'. <br />These 'blocks' of transactions are then processed one after another, in a chain. Block-chain.</p>

            <h5>Long answer</h5>
            <p>Think of a blockchain as a transaction list that is uploaded to thousands of computers around the world.&nbsp;</p>
            <p>If someone sends money to you, that transaction gets added to a queue. Queued transactions are sorted into groups (aka 'blocks') and then processed by one of the thousands of computers operating in the cryptocurrency's network. Once the transaction is complete, it appears on the public transaction list, or 'chain'.</p>
            <p>For every block of transactions a single computer completes, it is rewarded with crypto currency. There are two common methods used to win the right to process a block of transactions: Proof of Work and Proof of Stake.</p>
            <p>In Proof of Work, each computer completes a complex math problem, the first to solve the problem and 'prove the work', wins the right to process the block.</p>
            <p>In Proof of Stake, a computer wins the right to process a block based on how long it has been validating transactions and how many coins it has staked in its account (or 'stake pool').</p>
            <p><em>Ok, so what stops one of these computers from lying about the transaction value and skimming some cold hard cash off the top?</em></p>
            <p>Because this transaction list is sent publicly to thousands of individual computers across the world, if one of them tries to alter the transaction values, the others all 'call bullshit' and they lose the transaction.</p>
            <p>This way, digital payments can be sent securely without any centralized control (aka banks).</p>
        
        </>
    )
}


function ProtocolDef({}) {
    return (
        <>
            <h2>Protocol</h2>

            <p>What is a Protocol?</p>

            <p>A Protocol is a basic set of rules that allows data to be shared between computers.</p>

            <p>To put that into a crypto relevant context:</p>

            <p>The term 'Ethereum protocol' could also be referred to as the 'Ethereum rule set' - if you want to build something that works on the Ethereum Network, it needs to follow the Ethereum Protocol.</p>
        </>
    )
}

function LiquidityDef({}) {
    return (
        <>
            <h2>Liquidity</h2>

            <p>What is liquidity?</p>
            <p>In financial markets, liquidity typically means 'money' or 'assets' (e.g. liquidity provision = lending money or assets to others, so they can use them to trade).</p>
            <p>But liquidity also refers to how easy something is to buy, sell or trade.</p>
            <p>High liquidity means there's a big market for an asset, meaning it can be bought and sold quickly, at a steady market price.</p>
            <h5>For example:</h5>
            <p>Cash is highly liquid. You can trade it for goods and services just about anywhere in the world.</p>
            <p>Rare art is relatively illiquid. Buyers &amp; sellers are harder to come by, and a work's price can vary wildly from year to year, as demand fluctuates.</p>
            <p>In the crypto world, most investors look for tokens with high liquidity. They want to know that if they buy a cryptocurrency, they will be able to trade or sell it in the future.</p>

       </>
    )
}





export default Dictionary;
