import React, { Component } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import AuthService from './services/AuthService';
import { ModalProvider } from "/src/components/modal";

// Pages
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login";
import Accounts from "./pages/Accounts";
import ForgotPassword from "./pages/password/ForgotPassword";
import ResetPassword from "./pages/password/ResetPassword";
import Settings from "./pages/settings/Settings";
import Profile from "./pages/profile/Profile";
import Password from "./pages/profile/Password";
import Photo from "./pages/profile/Photo";
import Tokens from "./pages/profile/Tokens";
import Wallets from "./pages/profile/Wallets";
import Register from "./pages/register/Register";
import RegisterWallet from "./pages/register/RegisterWallet";
import RegisterBilling from "./pages/register/RegisterBilling";
import RegisterPlan from "./pages/register/RegisterPlan";
import RegisterInvite from "./pages/register/RegisterInvite";
import Users from "./pages/users/Users";
import Invite from "./pages/users/Invite";
import Report from "./pages/reports/Report";
import Reports from "./pages/reports/Reports";
import CreateReport from "./pages/reports/CreateReport";
import EditReport from "./pages/reports/EditReport";
import Courses from "./pages/courses/Courses";
import Course from "./pages/courses/Course";
import Dictionary from "./pages/Dictionary";

// Layouts
import RootLayout from "./layouts/RootLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";
import AccountLayout from './layouts/AccountLayout';
import ProfileLayout from "./layouts/ProfileLayout";


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            authUser: null,
        };
    }

    componentDidMount() {
        AuthService.observable.subscribe(x => this.setState({ authUser: x }));
    }

    render() {
        const router = createBrowserRouter(
            createRoutesFromElements(
                <Route element={<RootLayout />}>
                    <Route path="/login" element={<Login />} /> /** header=no | sidebar=no */
                    <Route path="/password/forgot" element={<ForgotPassword />} /> /** header=no | sidebar=no */
                    <Route path="/password/reset" element={<ResetPassword />} /> /** header=no | sidebar=no */
                    <Route path="/register" element={<Register />} /> /** header=no | sidebar=no */
                    <Route path="/register/invite" element={<RegisterInvite />} /> /** header=no | sidebar=no */

                   
                    <Route element={<ProtectedLayout />}>
                        <Route index element={<Accounts />} /> /** header=yes | sidebar=no */
                        <Route path="/register/wallet" element={<RegisterWallet />} /> /** header=yes | sidebar=no */
                        <Route path="/register/billing" element={<RegisterBilling />} /> /** header=yes | sidebar=no */
                        <Route path="/register/plan" element={<RegisterPlan />} /> /** header=yes | sidebar=no */
                       
                        <Route path="/dictionary/:term" element={<Dictionary />} /> /** header=no | sidebar=no */

                        <Route path="/profile" element={<ProfileLayout />}>
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/profile/password" element={<Password />} />
                            <Route path="/profile/photo" element={<Photo />} />
                            <Route path="/profile/wallets" element={<Wallets />} />
                            <Route path="/profile/tokens" element={<Tokens />} />
                        </Route>

                        <Route path="/:accountId" element={<AccountLayout />}>
                            <Route index element={<Dashboard />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/reports" element={<Reports />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/reports/create" element={<CreateReport />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/reports/:reportId" element={<Report />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/reports/:reportId/edit" element={<EditReport />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/settings" element={<Settings />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/users" element={<Users />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/users/invite" element={<Invite />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/courses" element={<Courses />} /> /** header=yes | sidebar=yes */
                            <Route path="/:accountId/courses/:courseId" element={<Course />} /> /** header=yes | sidebar=yes */

                        </Route>
                    </Route>

                    <Route path="*" element={<NotFound />} />
                </Route>
            )
        );

        return (
            <ModalProvider>
                <RouterProvider router={router} />
            </ModalProvider>
        );
    }

}

export default App;